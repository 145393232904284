let icsHeader = `
BEGIN:VCALENDAR
METHOD:PUBLISH
VERSION:2.0
CALSCALE:GREGORIAN
BEGIN:VTIMEZONE
TZID:Europe/Vienna
BEGIN:DAYLIGHT
TZOFFSETFROM:+0100
DTSTART:19810329T020000
TZNAME:MESZ
TZOFFSETTO:+0200
END:DAYLIGHT
BEGIN:STANDARD
TZOFFSETFROM:+0200
RRULE:FREQ=YEARLY;BYMONTH=10;BYDAY=-1SU
DTSTART:19961027T030000
TZNAME:MEZ
TZOFFSETTO:+0100
END:STANDARD
END:VTIMEZONE
`;

function dateToCalDate(dateIn) {    
    return(
        `${dateIn.getFullYear()}${String((dateIn.getMonth()+1)).padStart(2, '0')}${String((dateIn.getDate())).padStart(2, '0')}T${String((dateIn.getHours())).padStart(2, '0')}${String((dateIn.getMinutes())).padStart(2, '0')}${String((dateIn.getSeconds())).padStart(2, '0')}`
    )
}

export function createIcs(items) {    
    let ics = icsHeader;
    items.map((item, key) => {
        if(item.begin && item.end) {
            ics += buildEntry(item);
        }        
    })
    ics += '\nEND:VCALENDAR';
    return ics;
}

function buildEntry(item) {    
return `\nBEGIN:VEVENT
TRANSP:OPAQUE
DTSTART;TZID=Europe/Vienna:${dateToCalDate(new Date(item.begin))}
DTEND;TZID=Europe/Vienna:${dateToCalDate(new Date(item.end))}
UID:${item.id}-rpid
LOCATION:${(item?.location?.label_en)}
DESCRIPTION:${item.abstract}
URL;VALUE=URI:${item.url}
SUMMARY:${item.title}    
CREATED:${dateToCalDate(new Date())}
END:VEVENT`
}

// STATUS:${item.status}