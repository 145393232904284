import * as React from "react"
import { useCallback, useEffect, useState } from 'react';
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import sessions from "../../data/sessions.json"
import * as helper from './../helper/utils.js';
import * as statusHelper from './../helper/status.js';
import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { Table } from 'react-bootstrap';

import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import * as calendar from './../helper/calendar.js';

const SessionList = (props) => { 
  const [status, setStatus] = useState();
  const [searchPrefix, setSearchPrefix] = useState('');
  const [searchMain, setSearchMain] = useState('');
  const [displayList, setDisplayList] = useState();
  const [lastChange, setLastChange] = useState();
  const [hoverId, setHoverId] = useState();
  const [showDetails, setShowDetails] = useState(false);
  
  function createICS() {
    let test = calendar.createIcs(displayList);       
    const element = document.createElement("a");
    const file = new Blob([test], {
        type: "text/plain"
    });
    element.href = URL.createObjectURL(file);
    element.download = "rp23.ics";
    document.body.appendChild(element);
    element.click();
  }

  function toggleDetails(id) {
    if(showDetails) {
      if(id === hoverId) {
        setShowDetails(false);
        setHoverId(undefined);
      } else {
        setHoverId(id);  
        setShowDetails(true);
      }
    } else {
      setShowDetails(true);
      setHoverId(id);
    }    
  }

  async function wrapStatusSave(id, newStatus) {
    await statusHelper.save(id, newStatus);
    setLastChange(new Date());
  }
 
  let requestFilterTrack = useQueryParam("track", StringParam)[0];
  let requestFilterSpecial = useQueryParam("special", StringParam)[0];
  let requestFilterStatus = useQueryParam("status", NumberParam)[0];
  let requestFilterQuery = useQueryParam("query", StringParam)[0];
  
  useEffect(() => {
    setStatus(requestFilterStatus);
    let selectedSessions = [];

    if (typeof window !== `undefined`) {    
      selectedSessions = JSON.parse(window.localStorage.getItem('selectedSessions'));
    }
        
    let merged = sessions.data.map(t1 => ({...t1, ...selectedSessions?.find(t2 => t2.id === t1.id)}));
    // No status requested
    if(isNaN(requestFilterStatus)) { 
      setSearchPrefix('The ');
    // Selection Status requested
    } else {
      merged = sessions.data.map(t1 => ({...t1, ...selectedSessions?.find(t2 => t2.id === t1.id)}));
      setSearchPrefix('Your ');      

      if(requestFilterStatus < 5) {        
        merged = selectedSessions?.map(t1 => ({...t1, ...sessions.data.find(t2 => t2.id === t1.id)}));
        merged = merged?.filter(x => x.status === requestFilterStatus);
      } else {        
        merged = selectedSessions ? sessions.data.filter(o=> !selectedSessions.some(i=> i.id === o.id)) : sessions.data;
      }
    }
    
    if(requestFilterTrack) {      
      merged = merged.filter(x => x.track?.id === requestFilterTrack);
    }

    if(requestFilterSpecial) {
      let filterFix;
      if(requestFilterSpecial === 'TINCON') { filterFix = 'tincon-x-re-publica-22'; }
      if(requestFilterSpecial === 'txt') { filterFix = '-txt-long-live-the-word-'; }
      merged = merged.filter(x => x.subconference?.id === filterFix);
    }

    if(requestFilterQuery) {
      merged = merged.filter((obj) =>
        JSON.stringify(obj).toLowerCase().includes(requestFilterQuery.toLowerCase())
      );
      
    }

    merged = merged?.sort(function(a,b){return a.begin > b.begin ? 1 : a.begin < b.begin ? -1 : 0 });      
    setDisplayList(merged);  
        
  }, [requestFilterStatus, requestFilterTrack, requestFilterSpecial, requestFilterQuery, lastChange]);

  return (
  <Layout>
    <Seo title="Sessions" />
    <React.Fragment>    
        {(requestFilterTrack) ? <Badge size="sm" bg={requestFilterTrack}>{helper.trackName(requestFilterTrack)}</Badge> :''}
        {(requestFilterSpecial) ? <Badge size="sm" bg={requestFilterSpecial}>{requestFilterSpecial}</Badge> :''}
        <h1>
        {searchPrefix}list of&nbsp;
        {(requestFilterStatus) ? <b>{helper.nameStatus(requestFilterStatus)}&nbsp;</b> :''}
        {(requestFilterTrack) ? <span style={{  color: helper.trackColor(requestFilterTrack) }}>{helper.trackName(requestFilterTrack)}</span> :''}
        {(requestFilterSpecial) ? <span style={{  color: helper.trackColor(requestFilterSpecial) }}>{requestFilterSpecial}</span> : ''}
        
        <sup><small>{displayList ? displayList.length : 0}</small></sup></h1>
        <Table responsive><tbody>{ displayList 
          ?
          displayList.map((item, key) => {
            let session = sessions.data.find(x => x.id === item.id.toString());
            return (
              <tr key={item.id} class={(item?.cancelled ? "text-decoration-line-through" : "")}>
                <td class="text-nowrap d-none d-lg-block">
                  {(status != 1) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 1)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 1) }}>{helper.nameStatus(1)}</Button>&nbsp;</span> : ''}
                  {(status != 2) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 2)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 2) }}>{helper.nameStatus(2)}</Button>&nbsp;</span> : ''}
                  {(status != 3) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 3)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 3) }}>{helper.nameStatus(3)}</Button>&nbsp;</span> : ''}
                  {(status != 4) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 4)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 4) }}>{helper.nameStatus(4)}</Button></span> : ''}
                </td>
                <td>                  
                    <Badge bg={session?.track?.id}>{helper.trackName(session?.track?.id)?.substring(0,2).toUpperCase()}</Badge>
                    {(session?.subconference?.id === "-txt-long-live-the-word-") ? <React.Fragment>&nbsp;<Badge bg='txt'>.txt</Badge></React.Fragment> : ''}
                    {(session?.subconference?.id === "tincon-x-re-publica-22") ? <React.Fragment>&nbsp;<Badge bg='TINCON'>TINCON</Badge></React.Fragment> : ''}                  
                </td>
                <td className="text-nowrap">{helper.scheduleTime(item?.begin, item?.end)}</td>
                <td onClick={() => toggleDetails(item.id)}>
                  {(item?.cancelled) ? <span><Badge bg="danger">CANCELED</Badge>&nbsp;</span>:<React.Fragment></React.Fragment>}
                  <div class="d-lg-none">
                    {(status != 1) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 1)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 1) }}>{helper.nameStatus(1)}</Button>&nbsp;</span> : ''}
                    {(status != 2) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 2)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 2) }}>{helper.nameStatus(2)}</Button>&nbsp;</span> : ''}
                    {(status != 3) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 3)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 3) }}>{helper.nameStatus(3)}</Button>&nbsp;</span> : ''}
                    {(status != 4) ? <span><Button variant="light" size="sm" onClick={() => wrapStatusSave(item.id, 4)} style={{ backgroundColor: statusHelper.checkSelected(item.status, 4) }}>Interest</Button></span> : ''}                  
                  </div>
                  <Link to={`/session?id=${item.id}`} state={{ id: item.id}}>{session?.title}</Link>
                  {(showDetails && item.id === hoverId) ? <span>
                    <p></p>
                    {session.speakers.map((data, key) => {
        return (
          <div key={key}>{data.name}</div>
        );
      })}    
    
                    <br></br>{item?.abstract}
                    </span> : ''}
                </td>
                
                
              </tr>                
            );
            })
          :
          <tr><td>No choices yet</td></tr>
          }</tbody>
        </Table>
        { displayList && displayList.length > 0 
        ?
        <Button onClick={createICS} variant="outline-secondary">Calendar (ICS)</Button>
        :
        <React.Fragment></React.Fragment>
        }
    </React.Fragment>        
  </Layout>
)}

export default SessionList